import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "@components";
import { PRODUCT_PROVIDERS } from "@helpers/data";
import { useDeepCompareEffect } from "@hooks";
import {
  getFilteredProductPrices,
  setProductOverCosts,
} from "@store/product/ProductActions";

import DesktopPlanTable from "./DesktopPlanTable";
import MobilePlanTable from "./MobilePlanTable";
import { format } from "date-fns";
import { IRIS_DEROGATION_70_80, IRIS_DEROGATION_70_80_CRUCERO, IRIS_DEROGATION_CRUCERO } from "@constants/constants";
import { useEffect, useState } from "react";
import { axiosInstance } from "@api/axios";
import { calculateBadgeSpecific } from "@api/Endpoint";
import { showPriceDetails } from "@helpers/utils";

interface PlanTableProps {
  filter: any;
}

const PlanTable: React.FC<PlanTableProps> = (props) => {
  const { filter } = props;
  const [loadingCurrency, setLoadingCurrency] = useState<boolean>(false);
  const [pricesState, setPricesState] = useState<{ [key: string]: any }>({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { loading: userLoading }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.user
  );
  const {
    product,
    productOverCosts,
    loading: productLoading,
    originalPrices,
    irisExtraSelected,
    prices,
    price,
    currency: currencyProduct
  }: any = useSelector<any>(({ Travelfine }) => Travelfine.product);
  const { code, loading: loadingCode }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  const [searchParams] = useSearchParams();
  const variantParams: any = searchParams.get("variant");
  const extrasSelectedParams: any = searchParams.get("extrasSelected");
  const budgetIdParams: any = searchParams.get("budgetId");
  const hasIrisExtra: any = searchParams.get("hasIrisExtra");
  const codeKey = searchParams.get("code");
  const userTypeParams = searchParams.get("userType");

  useDeepCompareEffect(() => {
    if (extrasSelectedParams?.length > 0 || hasIrisExtra) {
      let extrasSelected: any[] = [];
      if (typeof extrasSelectedParams === 'string')
        extrasSelected = JSON.parse(extrasSelectedParams.replace("'", "")) ?? [];
      else
        extrasSelected = JSON.parse(extrasSelectedParams) ?? [];

      const newPrices = JSON.parse(JSON.stringify({ ...originalPrices }));
      const newArray: any = [];
      const newProductOverCosts = JSON.parse(JSON.stringify(productOverCosts));

      if (product?.extras?.length > 0) {
        // const allProvidersAreIRIS =
        //   product.providers &&
        //   Object.keys(product.providers).every(
        //     (item) => product.providers[item] === PRODUCT_PROVIDERS.IRIS
        //   );
        const isProductIris =
          product?.providers[variantParams] === PRODUCT_PROVIDERS.IRIS;

        if (!isProductIris) {
          let extraObj: any;
          extrasSelected?.forEach((e: any) => {

            if (!e._id.$oid) {
              extraObj = product?.extras.find(
                (item: any) => item._id === e._id
              );
            } else {
              extraObj = product?.extras.find(
                (item: any) => item._id?.$oid === e._id?.$oid
              );
            }

            if (extraObj) {
              let overcostAdded;
              let overcostType;
              if (Object?.keys(extraObj?.OverCostsObj).length > 0) {
                overcostAdded = extraObj?.OverCostsObj[variantParams]?.value;
                overcostType = extraObj?.OverCostsObj[variantParams].type;
              } else {
                let overcostObj = extraObj?.limitsList[variantParams].find(
                  ({ limit }: any) => limit.toString() === e.limit.toString()
                );
                overcostAdded = overcostObj?.overcost;
              }

              overcostAdded = Number(overcostAdded.toFixed(2));
              const limit = e?.limit;
              const obj = {
                _id: extraObj._id,
                id: extraObj.id,
                title: extraObj.title,
                overcost: overcostAdded,
                key: extraObj.key,
                overcostAdded,
                limit: limit,
                hasCode: extraObj.hasCode,
                overcostType,
              };

              newArray.push(obj);
            }

          });

          let totalOvercostsAdded = 0;
          newArray.forEach((item: any, i: any) => {
            if (item.overcostType !== "percentage") {
              totalOvercostsAdded = totalOvercostsAdded + item.overcostAdded;
            }
          });
          const totalStaticPrice =
            newPrices[variantParams] + totalOvercostsAdded;
          newArray.forEach((item: any, i: any) => {
            if (item.overcostType === "percentage") {
              totalOvercostsAdded =
                totalOvercostsAdded +
                (item?.overcostAdded * totalStaticPrice) / 100;
            }
          });

          newPrices[variantParams] =
            newPrices[variantParams] + totalOvercostsAdded;
          newProductOverCosts[variantParams] = newArray;

          dispatch(
            setProductOverCosts({
              productOverCosts: { ...newProductOverCosts },
              prices: { ...newPrices },
            })
          );
        } else {
          extrasSelected.forEach((e: any) => {
            const extraObj: any = product?.extras.find(
              (item: any) => item.id === e.id
            );
            const limit = e?.limit;

            const obj = {
              idGarantia: extraObj.id,
              Num_opcion_Contratacion: extraObj.Num_opcion_Contratacion,
              Capital: limit,
              limit: limit,
              title: extraObj.title,
            };
            newArray.push(obj);
          });
          newProductOverCosts[variantParams] = newArray;
          dispatch(
            getFilteredProductPrices({
              productId: product.productIds[variantParams],
              extras: newArray,
              filter: {
                origin: filter.origin,
                destination: filter.destination,
                departureDate: format(
                  new Date(filter.departureDate),
                  "yyyy-MM-dd"
                ),
                returnDate: format(new Date(filter.returnDate), "yyyy-MM-dd"),
                totalTravellers: filter.totalTravellers,
              },
              variant: variantParams,
              variants: product.variants,
              irisExtraSelected: irisExtraSelected[variantParams],
            })
          );
          dispatch(
            setProductOverCosts({
              productOverCosts: { ...newProductOverCosts },
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    extrasSelectedParams,
    variantParams,
    product?.extras,
    originalPrices,
    dispatch,
  ]);

  const buyClickHandler = (type: string, priceBuy?: any, currency?: any, convertedPrices?:any) => {
    const travellersPerPrice = product?.numberOfTravellersPerPrice || 1;
    const totalGroups = (code?.percentage === 0 || Object.keys(convertedPrices).length === 0) ? Math.ceil(filter?.totalTravellers / travellersPerPrice) : filter?.totalTravellers ;

    let price = Number(Number(priceBuy)?.toFixed(2)) * totalGroups;
    let irisExtraAll = [];

    if (product?.providers[type] === PRODUCT_PROVIDERS.IRIS) {
      if (irisExtraSelected && irisExtraSelected[type]?.derogation === IRIS_DEROGATION_CRUCERO) {
        ;
        irisExtraAll = product.irisExtras.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80_CRUCERO || extra?.derogation === IRIS_DEROGATION_CRUCERO);
      } else if (!irisExtraSelected[type]) {
        irisExtraAll = product.irisExtras.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80);
      }
    }

    if (price > 0) {
      const state = {
        type,
        price,
        precio_Neto:
          product?.irisPriceObj && product?.irisPriceObj[type]?.Precio_Neto,
        filter,
        productId: product?._id,
        productOverCosts: productOverCosts[type],
        productProvider: product.providers[type],
        coveragePdfFile:
          product?.coveragePdfFiles && product?.coveragePdfFiles[type],
        emailPdfFiles: product?.emailPdfFiles && product?.emailPdfFiles[type],
        budgetId: budgetIdParams,
        irisExtraSelected: irisExtraSelected[type],
        variantAllowBulkXlsxUpload:
          !!product?.variantAllowBulkXlsxUpload?.[type],

        variantAgeLimit: product?.variantsAgeLimit?.[type],
        irisExtraAll: irisExtraAll ?? [],
        variants: product.variants,
        productIdType: product.productIds[type],
        currency,
        axaId: 0
      };

      if (product.variantCodesAxa) {
        state.axaId = filter?.destination?.region === 'mexico' ? product.variantCodesAxa[type]?.nacional : product.variantCodesAxa[type]?.internacional;
      }

      if (filter?.origin?.value) {
        const eventData = {
          origin: filter?.origin?.value,
          destiny: filter?.destination?.value,
          departureDate: format(new Date(filter?.departureDate), "yyyy-MM-dd"),
          returnDate: format(new Date(filter?.returnDate), "yyyy-MM-dd"),
          totalTravellers: filter?.totalTravellers,
          modalidad: filter?.modality?.label
            ? filter?.modality?.label
            : filter?.modality,
          code: filter?.code,
          variant: type,
        };
        ReactGA.ga("send", {
          hitType: "event",
          eventCategory: "begin_checkout",
          eventAction: "begin_checkout",
          ...eventData,
        });
      }

      if (codeKey) {
        let queryParams = `?code=${codeKey}`;
        if (userTypeParams) {
          queryParams = queryParams + `&userType=${userTypeParams}`;
        }
        navigate(`/checkout/${queryParams}`, {
          state,
        });
      } else {
        navigate("/checkout", {
          state,
        });
      }
    } else {
      toast.error("¡El precio no puede ser cero!");
    }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const updatedPrices: { [key: string]: any } = {};
      if (!product?.variants || !Array.isArray(product.variants)) {
        return;
      }
      await Promise.all(product?.variants.map(async (variant: any) => {
        const currency = code?.currency ?? null;
        const badges = code?.badges ?? [];
        let priceVariantOriginal = (prices?.[variant] || price?.[variant]) ?? null;
        let priceVariant = (prices?.[variant] || price?.[variant]) ?? null;

        if (code?.badges?.length > 1 && product.currency.value !== currency.value) {
          if (currency && (badges && badges?.length > 0)) {
            setLoadingCurrency(true)
            try {
              const response = await axiosInstance.get(calculateBadgeSpecific(badges?.map((data: any) => data.value)?.join(','), priceVariantOriginal, currency?.value));
              const conversionPrice: any = Object.values(response?.data?.data).find((conversion: any) => conversion.from.includes(currencyProduct?.value) && conversion.to.includes(currency?.value))
              priceVariant = Number.parseFloat(conversionPrice?.value).toFixed(2);
            } catch (err: any) {
              console.log('Error: ' + err?.message);
            }
          }
        }

        const { totalPrice, totalWithoutDiscount, totalWithDiscount, perPersonWithoutDiscount, perPersonWithDiscount, labelPrice }: any = showPriceDetails({
          price: priceVariant,
          discountPercentage: code?.percentage,
          totalTravellers: filter.totalTravellers,
          numberOfTravellersPerPrice: product.numberOfTravellersPerPrice,
          symbol: currency?.symbol
        });

        updatedPrices[variant] = {
          price: priceVariant,
          priceOriginal: Number.parseFloat(Number.parseFloat(priceVariantOriginal)?.toFixed(2)),
          label: totalWithDiscount,
          labelWithoutPorcentage: totalWithoutDiscount,
          perPerson: code?.percentage ? labelPrice.perPersonWithDiscount : labelPrice.perPersonWithoutDiscount,
          perPersonWithDiscount: perPersonWithDiscount,
          perPersonWithoutDiscount: perPersonWithoutDiscount,
          totalPrice: totalPrice,
        };
      }));

      setPricesState(updatedPrices);
      setLoadingCurrency(false);
    };

    fetchPrices();
  }, [product, prices, dispatch, code, filter, currencyProduct, price]);

  return (
    <>
      <Loader loading={loading || productLoading || userLoading || loadingCurrency || loadingCode} />
      <div className="hidden mb-4 lg:mx-10 lg:block">
        {!loadingCurrency && <DesktopPlanTable
          product={product}
          buyClickHandler={buyClickHandler}
          totalTravellers={filter.totalTravellers}
          filter={filter}
          variantParams={variantParams}
          pricesState={pricesState}
        />}
      </div>
      <div className="block my-4 lg:hidden sm:mx-2">
        {!loadingCurrency && <MobilePlanTable
          product={product}
          buyClickHandler={buyClickHandler}
          totalTravellers={filter.totalTravellers}
          filter={filter}
          variantParams={variantParams}
          pricesState={pricesState}
        />}
      </div>
    </>
  );
};

export default PlanTable;
