import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Button, ExternalLink } from "@components";
import { RequestBudget } from "@components/modals";
import { ShareBudget } from "@components/SharedBugget";
import { getPrice, showPrice } from "@helpers/utils";
import { Paper, Tooltip } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import Extra from "./Extra";
import Guarantee from "./Guarantee";
import MobileGuranteeItem from "./MobileGuranteeItem";
import CurrencySelector from "@components/CurrencySelectorProps";

SwiperCore.use([Pagination]);

interface TableProps {
  type: string;
  price: any;
  product: any;
  buyClickHandler: any;
  totalPrice: any;
  totalTravellers: number;
  filter: any;
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  pricesState: any;
}

const Table: React.FC<TableProps> = (props) => {
  const {
    type,
    price,
    product,
    buyClickHandler,
    totalPrice,
    totalTravellers,
    filter,
    showDetails,
    setShowDetails,
    pricesState
  } = props;

  const useStyles = makeStyles((theme: any) => ({
    hover: {
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  }));

  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<any>({});
  const [convertedPrices, setConvertedPrices] = useState<{ [variant: string]: number }>({});
  const [selectedCurrencies, setSelectedCurrencies] = useState<{ [variant: string]: string }>({});
  
  const { prices }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );

  const { numberOfTravellersPerPrice } = product;
  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);
  const { badges, currency }: any = code;
  const handleOpen = (variant: string) => {
    setOpen(true);
    const productId = product?._id;
    const productVariant = variant;

    const price = getPrice({
      price: prices[variant],
      totalTravellers,
      numberOfTravellersPerPrice,
    });

    setFilterData({
      ...filter,
      productId,
      productVariant,
      price,
    });
  };
  
  const handleCurrencyChange = (currency: string, variant: string) => {
    setSelectedCurrencies((prevCurrencies) => ({
      ...prevCurrencies,
      [variant]: currency,
    }));
  };
  
  const handleBuyClick = (variant: string) => {
    const percentage = code?.percentage || 0;
  
    const originalPrices = convertedPrices ? Object.keys(convertedPrices).reduce((acc, key) => {
      const priceWithDiscount = convertedPrices[key];
      acc[key] = percentage > 0
        ? (priceWithDiscount / (1 - percentage / 100)) / totalTravellers
        : (priceWithDiscount / totalTravellers);
      return acc;
    }, {} as Record<string, number>) : Number.parseFloat(pricesState[variant]);
    const price: any = Object.keys(originalPrices)?.length > 0 ? originalPrices : null;
    const currency = code?.badges?.find((current: any) => current?.value === selectedCurrencies[variant]) || code?.currency;
  
    buyClickHandler(variant, price ?  Number.parseFloat(price[variant]) : pricesState[variant].perPersonWithoutDiscount, currency, convertedPrices);
  };
  
  const handleClose = () => {
    setOpen(false);
    setFilterData({});
  };

  const classes = useStyles();
  
  return (
    <>
      <Paper elevation={3} className="overflow-auto rounded-md">
        <table className="w-full border border-collapse border-gray-100 rounded-md">
          <thead>
            <tr>
              <th className="items-center px-3 py-6 border border-gray-100">
                <h1 className="text-2xl font-medium capitalize">
                  {product?.title} {type}
                </h1>
                <h2 className="text-2xl font-black">{pricesState[type]?.label}</h2>
                {!!code?.percentage && (
                  <h3 className="text-xl font-black line-through">
                    {pricesState &&
                      showPrice({
                       price: pricesState[type]?.price || 0 ,
                        totalTravellers,
                        numberOfTravellersPerPrice,
                        symbol: currency?.symbol
                      })}
                  </h3>
                )}
                {totalTravellers > 1 && (
                  <h3 className="text-lg font-bold">
                  {pricesState &&  pricesState[type]?.perPerson}
                    <span className="text-xs font-normal text-gray-500">
                      / persona
                    </span>
                  </h3>
                )}
                <>
                  <div className="mt-2 border-t" />
                  <div className="flex flex-col py-2">
                    {product?.guarantees &&
                      product?.guarantees.map((guarantee: any) => (
                        <React.Fragment key={guarantee.title}>
                          {guarantee.items.map(
                            (item: any) =>
                              item?.isShown?.onMobile && (
                                <MobileGuranteeItem
                                  key={item.id}
                                  item={item}
                                  type={type}
                                />
                              )
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </>
                {badges?.length > 1 && (
                      <CurrencySelector
                        price={pricesState[type]?.totalPrice}
                        priceLabel={pricesState[type]?.label}
                        variant={type}
                        currencies={badges}
                        className={'w-20 h-10 text-sm'}
                        selectedCurrency={selectedCurrencies[type] || currency?.value}
                        onChange={(newCurrency, variant) => handleCurrencyChange(newCurrency, variant)}
                        setSelectedCurrencies={handleCurrencyChange}
                        setConvertedPrices={setConvertedPrices}
                      />
                    )}
                <Button
                  color="secondary"
                  className="mt-2 text-base cursor-pointer"
                  onClick={() => handleBuyClick(type)}
                >
                  Contratar
                </Button>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {showDetails && (
              <>
                {product?.guarantees &&
                  product?.guarantees.map((guarantee: any) => (
                    <React.Fragment key={guarantee.title}>
                      <tr key={guarantee.title}>
                        <td
                          colSpan={4}
                          className="py-2 border border-gray-100 bg-gray-50"
                        >
                          <p className="ml-4 font-bold">{guarantee.title}</p>
                        </td>
                      </tr>
                      {guarantee.items.map(
                        (item: any) =>
                          !item?.isHidden?.onWeb && (
                            <Guarantee key={item.id} item={item} type={type} />
                          )
                      )}
                    </React.Fragment>
                  ))}
                {product?.extras &&
                  product?.extras.map((item: any) => (
                    <Extra key={item.id} item={item} type={type} />
                  ))}
                {product?.irisExtras &&
                  product?.irisExtras.filter((extra: any) => extra?.is_hidden ? extra?.is_hidden === false : true).map((item: any) => (
                    <Tooltip
                      placement="top"
                      title="Tomador del Seguro La persona que celebra el contrato  y que es responsable del pago de la prima."
                    >
                      <Extra key={item.id} item={item} type={type} isIRISExtra />
                    </Tooltip>
                  ))}
              </>
            )}
            <tr>
              <td className="flex justify-center pb-3 text-base">
                <p
                  className="mt-2 underline cursor-pointer"
                  onClick={() => setShowDetails((prevShowDetails: boolean) => !prevShowDetails)}
                >
                  {!showDetails ? "Ver detalles" : "Ocultar detalles"}
                </p>
              </td>
            </tr>
            <tr>
              <td className="w-full px-4 pt-2 pb-8 border border-gray-100 lg:pb-2">
                <div className="flex flex-col items-center">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => handleOpen(type)}
                    className="cursor-pointer"
                  >
                    Guardar Presupuesto
                  </Button>
                  {product?.coveragePdfFiles &&
                    product?.coveragePdfFiles[type] && (
                      <ExternalLink href={product?.coveragePdfFiles[type]}>
                        <p
                          className={`hover:underline cursor-pointer mt-2 transition duration-300 ease-in-out ${classes.hover}`}
                        >
                          Más coberturas
                        </p>
                      </ExternalLink>
                    )}
                  <ShareBudget
                    className="flex justify-center w-full m-1"
                    data={{
                      ...filter,
                      productId: product?._id,
                      productVariant: type,
                      productProvider: product?.providers[type] ?? ''
                    }}
                    productId={product?._id}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
      <RequestBudget open={open} onClose={handleClose} filter={filterData} />
    </>
  );
};

interface MobilePlanTableProps {
  product: any;
  buyClickHandler: any;
  totalTravellers: number;
  filter: any;
  pricesState?: any;
  variantParams?: any;
}

const MobilePlanTable: React.FC<MobilePlanTableProps> = (props) => {
  const { product, buyClickHandler, totalTravellers, filter, variantParams, pricesState } = props;

  const [swiper, setSwiper] = useState<any>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const theme: any = useTheme();

  const slideTo = (index: any) => {
    swiper?.slideTo(index);
  };

  const { prices }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );
  
  const { numberOfTravellersPerPrice } = product;

  const [showDetailsMap, setShowDetailsMap] = useState(new Map());

  const toggleShowDetails = (variant: string) => {
    setShowDetailsMap((prevShowDetailsMap) => {
      const newShowDetailsMap = new Map(prevShowDetailsMap);
      newShowDetailsMap.set(variant, !newShowDetailsMap.get(variant));
      return newShowDetailsMap;
    });
  };

  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);
  const { currency }:any = code;

  const textsMobileSize =
    product?.variants?.length >= 3 ? "sm:text-sm" : "sm:text-md";
  const isActive: any = (index: number) => activeSlide === index;

  useEffect(() => {
    if (variantParams && product?.variants?.length > 0) {
      product?.variants.map((variant: any, index: number) => {
        variant === variantParams && slideTo(index);
      });
    }
  }, [variantParams, product]);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <table className="sticky top-0 z-20 w-full border-collapse">
          <thead>
            <tr className="flex flex-wrap justify-center sm:flex-nowrap">
              {product?.variants?.length > 0 &&
                product?.variants.map((variant: any, index: number) => (
                  <th
                    className={` sm:border p-2 sm:border-gray-100  cursor-pointer items-center shadow-md flex-1`}
                    style={{
                      backgroundColor: isActive(index)
                        ? theme.palette.secondary.main
                        : "white",
                      color: isActive(index) ? theme.palette.secondary.contrastText : "",
                    }}
                    key={variant}
                    onClick={() => slideTo(index)}
                  >
                    <div className="flex flex-col overflow-hidden place-content-center">
                      <h1
                        className={`text-xs  ${textsMobileSize} text-center whitespace-normal obreak-words`}
                      >
                        {product?.title}
                      </h1>
                      <h1
                        className={`text-xs ${textsMobileSize} sm:font-bold my-1 text-center whitespace-normal break-words`}
                      >
                        {variant}
                      </h1>
                      <h2
                        className={`text-xs ${textsMobileSize}  text-center whitespace-normal break-words`}
                      >
                       {pricesState &&  pricesState[variant]?.label}
                      </h2>
                      {!!code?.percentage && (
                        <h2 className="text-xs font-bold text-center line-through whitespace-normal sm:text-sm">
                          {pricesState &&  pricesState[variant]?.labelWithoutPorcentage}
                        </h2>
                      )}
                      {totalTravellers > 1 && (
                        <>
                          <h3 className="text-xs font-normal text-center whitespace-normal sm:text-sm">
                          {pricesState &&  pricesState[variant]?.perPerson}
                          </h3>
                          <p className="text-xs font-normal text-center text-gray-500">
                            persona
                          </p>
                        </>
                      )}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
        </table>
      </div>
      <Paper elevation={3} className="mt-4 overflow-auto rounded-md minxs:mx-2">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={true}
          scrollbar={{ draggable: true }}
          onSwiper={setSwiper}
          onSlideChange={(e) => setActiveSlide(e.activeIndex)}
          allowSlideNext
        >
          {product?.variants?.length > 0 &&
            product?.variants.map((variant: any) => (
              <SwiperSlide key={variant}>
                <Table
                  type={variant}
                  price={prices && prices[variant]}
                  product={product}
                  buyClickHandler={buyClickHandler}
                  totalPrice={
                    prices &&
                    showPrice({
                      price: prices[variant],
                      discountPercentage: code?.percentage,
                      totalTravellers,
                      numberOfTravellersPerPrice,
                      symbol: currency?.symbol
                    })
                  }
                  totalTravellers={totalTravellers}
                  filter={filter}
                  showDetails={showDetailsMap.get(variant) || false}
                  setShowDetails={() => toggleShowDetails(variant)}
                  pricesState={pricesState}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </Paper>
    </div>
  );
};

export default MobilePlanTable;
